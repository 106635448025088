import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";

const ContentPage = () => {
  const keyRef = 'exoscale';
  const provider = 'Exoscale';

  return (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      refKey="snapshots"
      title="Flexible Exoscale Droplet & Volume Backup"
      description="Automate your Exoscale Droplet & Volume backups. Set a schedule, a storage and let it work in peace."
    />
    <Layout.Hero
      type="primary"
      title={(<>Automated <span className="text-primary">Exoscale server</span> backups</>)}
      subTitle={(
        <>Automatically back up your Servers.<br/>Recover your data in minutes, and focus on building incredible things.
        </>)}
      checkMarks={['Cloud Compute backup']}
      icons={[
        <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Fully integrated with:</p>,
        <Ui.Image filename="brands/icons/exoscale-ico.png" alt="Exoscale backup" className="w-6"/>,
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your Exoscale Backup" urlAtrr={{ sb_source: "website", sb_term: "exoscale-hero" }} />)}
      illustration={(
        <Ui.Image className="" filename={`simplebackups-${keyRef}-snapshot-hero.png`} alt={`SimpleBackups ${provider} snapshot`}/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                preTitle: "Exoscale backups got better",
                title: (<>Flexible & unrestricted scheduling for Exoscale</>),
                description: (
                  <>
                    <p>
                      Weekly Exoscale backups just don’t cut it.<br/>
                      Unlock flexible scheduling & retention for your Exoscale backups.<br/>

                      <Ui.FeatureChecks items={[
                        'Full control of when your backup will run', 'Unrestricted retention', 'Unlimited backup intervals (down to every minute)']} />
                      <Ui.Link arrow="right" to="#features">View all features</Ui.Link>
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename='simplebackups-app-scheduling'
                  alt={`${provider} Backup custom scheduling`}
                  style={{maxWidth: 450}}
                />)
              },
              {
                title: (<>Droplet & Volume backup</>),
                description: (
                  <>
                    <p>
                      Easily connect your Exoscale account(s) using our connector.<br/>
                      Select the Droplets or Spaces you want to backup and you're done.
                      Get rid of Exoscale's limited and expensive backup management and get full control over your backups.
                      <Ui.FeatureChecks items={['Server Backup', 'Unlimited connected accounts']} />
                      <Ui.Link arrow="right" to="#features">View all features</Ui.Link>
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename={`simplebackups-app-backup-lifecycle.png`}
                  alt={`${provider} backup `}
                  style={{maxWidth: 450}}
                />)
              },
              Content_FeatureTrust(),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt" id="features">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">Backups a a Service, you're covered!</Ui.Typography>
          <div className="text-center">
            <p className="mb-6">SimpleBackups is fully integrated with Exoscale, meaning all is configurable from an easy-to-use UI.</p>
            <Sections.FeatureSlider tags={["snapshot"]} />
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about Exoscale Server Backups?</>}
      text="Find out more and get started with our Exoscale Backup guides"
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['exoscale_what_is_snapshot', 'how_snapshot', 'snapshot_where', 'use_for_other_things']}/>

  </Layout.Layout>
)}

export default ContentPage
